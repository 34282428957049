/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Flex } from 'theme-ui';

import messages from './intl';

import { RefundServiceCharge } from '../../../@types/enums';
import {
  selectBookingData,
  selectConfig,
  selectDazzlerLocale,
  selectLoginUrl,
  selectRefundData,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import CheckBoxButton from '../../common/checkboxbutton/CheckBoxButton';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';
import { resolveLocalisedStringOrDefault } from '../helpers';
import globalMessages from '../intl';
import loginMessages from '../login/intl';
import { WidgetData } from '../types';

interface Props {
  widget: WidgetData<'TicketingCMSJourneyRefundDetailsWidget'>;
  handleSubmitRefund: (
    isLoyaltyRefund: boolean,
    refundIncludesServiceCharge: boolean
  ) => void;
}

const RefundRequest: React.FC<Props> = ({ widget, handleSubmitRefund }) => {
  const locale = useSelector(selectDazzlerLocale);
  const { formatMessage } = useIntl();
  const { orderId } = useParams();

  const config = useSelector(selectConfig);
  const bookingData = useSelector(selectBookingData);
  const loginUrl = useSelector(selectLoginUrl);
  const refundData = useSelector(selectRefundData);

  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleLogInClick = () => {
    const returnPath = `/api/refund/redirect/${orderId}`;
    const url = `${loginUrl}?return=${returnPath}`;
    window.location.assign(url);
  };

  const loginSection = () => {
    return (
      <ActionButton
        onClick={handleLogInClick}
        showIcon
        contained
        mb={0}
        variant='primary'
      >
        {formatMessage(loginMessages.loginButtonText)}
      </ActionButton>
    );
  };

  const termsAndConditions = () => {
    return (
      <Flex sx={{ mt: 5, alignItems: 'center' }}>
        <CheckBoxButton checked={checked} onClick={handleCheckboxChange} />
        <Box
          sx={{
            alignItems: 'center',
            p: { m: 0, fontWeight: checked ? 'bold' : 'normal' },
          }}
        >
          <RichText
            text={resolveLocalisedStringOrDefault(
              formatMessage(messages.refundAgreeTermsText),
              locale,
              widget.shape?.refundAgreeTermsText
            )}
            styles={{
              textAlign: 'left',
            }}
          />
        </Box>
      </Flex>
    );
  };

  if (
    refundData?.isRefundAllowedForMemberLevelId &&
    !bookingData.isUserValidated &&
    config.currentCinema.refundServiceCharge === RefundServiceCharge.NO
  ) {
    return (
      <ContainedRow dataTestId='refund-request-is-allowed-for-member-level'>
        <RichText
          text={resolveLocalisedStringOrDefault(
            formatMessage(messages.loyaltyLoginRefundRequestMessage),
            locale,
            widget.shape?.loyaltyLoginRefundRequestMessage
          )}
        />
        {loginSection()}
      </ContainedRow>
    );
  }

  return (
    <>
      {config.currentCinema.refundServiceCharge ===
        RefundServiceCharge.LOYALTY_ONLY &&
        !bookingData.isUserValidated && (
          <ContainedRow dataTestId='refund-request-loyalty-service-charge'>
            <RichText
              text={resolveLocalisedStringOrDefault(
                formatMessage(messages.loyaltyLoginRefundRequestMessage),
                locale,
                widget.shape?.loyaltyLoginRefundRequestMessage
              )}
            />
            {loginSection()}
            <Box mt={6}>
              <RichText
                text={resolveLocalisedStringOrDefault(
                  formatMessage(messages.continueAsGuestMessage),
                  locale,
                  widget.shape?.continueAsGuestMessage
                )}
              />
            </Box>

            {termsAndConditions()}

            <ActionButton
              onClick={() => handleSubmitRefund(false, false)}
              showIcon
              contained
              hasMarginX
              disabled={!checked}
              mb={0}
              variant='primary'
            >
              {formatMessage(globalMessages.continueAsGuestButtonText)}
            </ActionButton>
          </ContainedRow>
        )}

      {config.currentCinema.refundServiceCharge ===
        RefundServiceCharge.LOYALTY_ONLY &&
        bookingData.isUserValidated && (
          <ContainedRow dataTestId='refund-signed-in'>
            <RichText
              text={resolveLocalisedStringOrDefault(
                formatMessage(messages.loyaltyRefundRequestConfirmation),
                locale,
                widget.shape?.loyaltyRefundRequestConfirmation
              )}
            />
            <ActionButton
              onClick={() => handleSubmitRefund(true, true)}
              showIcon
              contained
              variant='primary'
            >
              {formatMessage(globalMessages.confirmButtonText)}
            </ActionButton>
          </ContainedRow>
        )}

      {config.currentCinema.refundServiceCharge === RefundServiceCharge.YES && (
        <ContainedRow dataTestId='refund-request-yes-service-charge'>
          <RichText
            text={resolveLocalisedStringOrDefault(
              formatMessage(messages.genericRefundRequestMessage),
              locale,
              widget.shape?.genericRefundRequestMessage
            )}
          />
          <ActionButton
            onClick={() => handleSubmitRefund(false, true)}
            showIcon
            contained
            mb={0}
            variant='primary'
          >
            {formatMessage(globalMessages.confirmButtonText)}
          </ActionButton>
        </ContainedRow>
      )}

      {config.currentCinema.refundServiceCharge === RefundServiceCharge.NO && (
        <ContainedRow dataTestId='refund-request-no-service-charge'>
          <RichText
            text={resolveLocalisedStringOrDefault(
              formatMessage(messages.nonLoyaltyRefundRequestConfirmation),
              locale,
              widget.shape?.nonLoyaltyRefundRequestConfirmation
            )}
          />

          {termsAndConditions()}

          <ActionButton
            onClick={() => handleSubmitRefund(false, false)}
            showIcon
            contained
            disabled={!checked}
            mb={0}
            variant='primary'
          >
            {formatMessage(globalMessages.confirmButtonText)}
          </ActionButton>
        </ContainedRow>
      )}
    </>
  );
};

export default RefundRequest;
