/** @jsxImportSource theme-ui */
import React, { useEffect, createRef, useState } from 'react';

import moment from 'moment';
import { Form, Overlay, Tooltip } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import GiftCardPaymentForm from './GiftCardPaymentForm';

import {
  CheckedGiftCard,
  MakePaymentModelOverrideProps,
} from '../../../../@types/modelTypes';
import { PEACH_CODES } from '../../../../constants';
import { useTurnstile } from '../../../../contextProviders/turnstileContext';
import { usePrevious } from '../../../../hooks/usePrevious';
import { getContentForError } from '../../../../services/PeachErrorResolver';
import backend from '../../../../services/RestUtilities';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectAppliedGiftCards,
  selectBankCardAmount,
  selectConfig,
  selectContent,
  selectCustomer,
  selectLoyaltyRecognitionNumber,
  selectGrandTotalWithoutDonationInCents,
  selectToken,
} from '../../../../store/Selectors';
import ActionButton from '../../../common/actionbutton/ActionButton';
import ActionButtonSpinner from '../../../common/actionbuttonspinner/ActionButtonSpinner';
import RichText from '../../../common/richtext/RichText';
import { resolveTicketingCMSStringOrDefault } from '../../helpers';
import globalMessages from '../../intl';
import messages from '../intl';

interface Props {
  isShown: boolean;
  handleValidatePage: () => void;
  setPaymentFormVisibility: (visibility: boolean) => void;
  isPageValidated: boolean;
  giftCardProviderAcceptsPin?: boolean;
}

const GiftCardPayment: React.FC<Props> = ({
  isShown,
  handleValidatePage,
  setPaymentFormVisibility,
  isPageValidated,
  giftCardProviderAcceptsPin = false,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const prevIsShown = usePrevious(isShown);
  const turnstile = useTurnstile();

  const appliedGiftCards = useSelector(selectAppliedGiftCards);

  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const loyaltyRecognitionNumber = useSelector(selectLoyaltyRecognitionNumber);
  const token = useSelector(selectToken);

  const grandTotalWithoutDonationInCents = useSelector(
    selectGrandTotalWithoutDonationInCents
  );
  const bankCardAmount = useSelector(selectBankCardAmount);
  const [isFormValid, setIsFormValid] = useState(false);
  const [giftCardNumberInputValue, setGiftCardNumberInputValue] = useState('');
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  const [isCheckButtonDisabled, setIsCheckButtonDisabled] = useState(true);
  const [isGiftCardNumberInputDisabled, setIsGiftCardNumberInputDisabled] =
    useState(true);
  const [isCheckInProgress, setIsCheckInProgress] = useState(false);
  const [isMaxNumberOfGiftCardsUsed, setIsMaxNumberOfGiftCardsUsed] =
    useState(false);
  const [checkedGiftCardState, setCheckedGiftCardState] =
    useState<CheckedGiftCard>({
      giftCardBalanceInCents: 0,
      giftCardNumber: '',
      giftCardPin: '',
      isChecked: false,
    });
  const giftCardNumberInput = createRef<HTMLInputElement>();
  const giftCardPinInput = createRef<HTMLInputElement>();

  const giftCardCoversFullPayment = bankCardAmount === 0;

  const showPinInput = giftCardProviderAcceptsPin;

  useEffect(() => {
    if (isShown !== prevIsShown && !isShown) {
      setCheckedGiftCardState((prevState) => ({
        ...prevState,
        giftCardBalanceInCents: 0,
        giftCardNumber: '',
        giftCardPin: '',
        isChecked: false,
      }));
      !giftCardCoversFullPayment && setPaymentFormVisibility(true);
    }
  }, [
    isShown,
    prevIsShown,
    dispatch,
    setPaymentFormVisibility,
    giftCardCoversFullPayment,
  ]);

  useEffect(() => {
    setIsFormValid(
      !appliedGiftCards?.find((x) => !x.giftCardNumber) &&
        !appliedGiftCards?.find((x) => !x.giftCardBalanceInCents) &&
        !!customer?.isValid
    );
  }, [appliedGiftCards, checkedGiftCardState, customer]);

  const isApplied = !!appliedGiftCards?.find(
    (giftCard) => giftCard.giftCardNumber === giftCardNumberInputValue
  );

  const handleGiftCardCheck = async () => {
    const giftCardNumber = giftCardNumberInput?.current?.value ?? '';
    const giftCardPin = giftCardPinInput?.current?.value ?? '';

    if (!giftCardNumber) return;
    setIsCheckInProgress(true);

    const turnstileToken = await turnstile?.getToken();

    const data = {
      giftCardNumber,
      giftCardPin,
      dataToken: token,
    };
    const response = await backend.post(
      'api/GiftCard/CheckBalance',
      data,
      turnstileToken
    );
    if (response.ok) {
      const responseContent = response.content;
      if (responseContent.peachCode === PEACH_CODES.noError) {
        let formattedExpiryDate: string | undefined;
        const { isExpired, cardExpiry } = responseContent;

        if (cardExpiry) {
          formattedExpiryDate = moment(cardExpiry).format('yyyy-MM-DD');
        }

        const giftCardBalanceInCents = response.content.balanceRemaining;
        setCheckedGiftCardState((prevState) => ({
          ...prevState,
          giftCardBalanceInCents,
          giftCardNumber,
          giftCardPin,
          isChecked: true,
          cardExpiry: formattedExpiryDate,
          isExpired,
        }));
      } else {
        dispatch(
          actionCreators.setError(
            getContentForError(responseContent.peachCode, content),
            responseContent.peachCode
          )
        );
      }
    } else {
      dispatch(
        actionCreators.setError(content.error.giftCardBalanceErrorRichText)
      );
    }

    turnstile?.resetToken();

    setIsCheckInProgress(false);
  };

  const handleMakeGiftCardPayment = async () => {
    if (!isFormValid) return;
    const makePaymentModelOverrideProps: MakePaymentModelOverrideProps = {
      loyaltyCardNumber: loyaltyRecognitionNumber,
      ...(giftCardCoversFullPayment && {
        totalCharge: grandTotalWithoutDonationInCents,
        donationInCents: 0,
      }),
    };
    dispatch(
      actionCreators.submitMakePayment({
        makePaymentModelOverrideProps,
        turnstile,
      })
    );
  };

  const handleMakeGiftCardPaymentClick = () => {
    handleMakeGiftCardPayment();
  };

  const handleTooltip = (showTooltip: boolean) => {
    if (giftCardCoversFullPayment) {
      setShowInfoTooltip(showTooltip);
    }
  };

  useEffect(() => {
    appliedGiftCards?.length <
    (config.currentCinema.maxNumberOfGiftCardsAllowed || 1)
      ? setIsMaxNumberOfGiftCardsUsed(false)
      : setIsMaxNumberOfGiftCardsUsed(true);
  }, [appliedGiftCards, config.currentCinema.maxNumberOfGiftCardsAllowed]);

  useEffect(() => {
    setIsCheckButtonDisabled(
      !giftCardNumberInputValue ||
        isMaxNumberOfGiftCardsUsed ||
        giftCardCoversFullPayment ||
        isApplied
    );
  }, [
    giftCardCoversFullPayment,
    giftCardNumberInputValue,
    isApplied,
    isMaxNumberOfGiftCardsUsed,
  ]);

  useEffect(() => {
    setIsGiftCardNumberInputDisabled(
      isMaxNumberOfGiftCardsUsed || giftCardCoversFullPayment
    );
  }, [giftCardCoversFullPayment, isMaxNumberOfGiftCardsUsed]);

  const renderActionButton = () => (
    <ActionButton
      disabled={isCheckButtonDisabled || isCheckInProgress}
      onClick={handleGiftCardCheck}
      variant='secondary'
      mb={0}
      mt={0}
    >
      {isCheckInProgress ? (
        <ActionButtonSpinner />
      ) : (
        formatMessage(globalMessages.checkButtonText)
      )}
    </ActionButton>
  );

  return (
    <div className='giftcard-payment' data-testid='gift-card-payment'>
      <RichText
        text={resolveTicketingCMSStringOrDefault(
          formatMessage(messages.giftCardEnterRichText),
          content.payment.giftCardEnterRichText
        )}
      />
      <Form sx={{ mt: 2 }}>
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ pr: 3, flexGrow: 1 }}>
            <Overlay
              placement='top'
              show={showInfoTooltip}
              target={giftCardNumberInput}
            >
              <Tooltip
                data-testid='custom-amount-tooltip'
                id='maxAmountLimitReached'
              >
                {resolveTicketingCMSStringOrDefault(
                  formatMessage(messages.giftCardInputTooltipText),
                  content.payment.giftCardInputTooltipText
                )}
              </Tooltip>
            </Overlay>
            <Form.Control
              className='spaced-letters'
              onChange={(e) =>
                setGiftCardNumberInputValue(
                  e.currentTarget.value.replace(/\s+/g, '')
                )
              }
              id='giftCardNumber'
              name='giftCardNumber'
              type='text'
              onFocus={() => handleTooltip(true)}
              onBlur={() => handleTooltip(false)}
              placeholder={resolveTicketingCMSStringOrDefault(
                formatMessage(messages.giftCardPlaceholder),
                content.payment.giftCardPlaceholder
              )}
              ref={giftCardNumberInput}
              value={giftCardNumberInputValue}
              aria-disabled={isGiftCardNumberInputDisabled || isCheckInProgress}
              disabled={isGiftCardNumberInputDisabled || isCheckInProgress}
            />
          </Box>
          {showPinInput ? (
            <Box sx={{ maxWidth: '74px' }}>
              <Form.Control
                id='giftCardPin'
                name='giftCardPin'
                type='number'
                placeholder={resolveTicketingCMSStringOrDefault(
                  formatMessage(messages.giftCardPinPlaceholder),
                  content.payment?.giftCardPinPlaceholder
                )}
                ref={giftCardPinInput}
              />
            </Box>
          ) : (
            <Box>{renderActionButton()}</Box>
          )}
        </Flex>
        {showPinInput && (
          <Flex sx={{ flexGrow: 1, mt: 3 }}>{renderActionButton()}</Flex>
        )}
      </Form>
      <GiftCardPaymentForm
        amountToPayByBankCard={bankCardAmount}
        checkedGiftCardState={checkedGiftCardState}
        isFormValid={isFormValid}
        giftCardCoversFullPayment={giftCardCoversFullPayment}
        isMaxNumberOfGiftCardsUsed={isMaxNumberOfGiftCardsUsed}
        setGiftCardNumberInputValue={setGiftCardNumberInputValue}
        setCheckedGiftCardState={setCheckedGiftCardState}
        setIsCheckButtonDisabled={setIsCheckButtonDisabled}
        setPaymentFormVisibility={setPaymentFormVisibility}
        isPageValidated={isPageValidated}
        handleMakeGiftCardPaymentClick={handleMakeGiftCardPaymentClick}
        handleValidatePage={handleValidatePage}
      />
    </div>
  );
};

export default GiftCardPayment;
