/** @jsxImportSource theme-ui */
import React from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import messages from './intl';
import PaymentIconsContainer from './paymenticons/PaymentIconsContainer';

import { PaymentProvidersEnum } from '../../../@types/enums';
import { PaymentConfig } from '../../../@types/modelTypes';
import { selectContent } from '../../../store/Selectors';
import { ReactComponent as AmazonPaySVG } from '../../../svgs/payment/amazonPayLogo.svg';
import { resolveTicketingCMSStringOrDefault } from '../helpers';

interface Props {
  paymentProvider: PaymentProvidersEnum;
  creditCardType: string;
  hasOnePaymentOption: boolean;
  paymentConfig: PaymentConfig;
}

const CreditCardPaymentsHeadingContent: React.FC<Props> = ({
  paymentProvider,
  creditCardType,
  hasOnePaymentOption,
  paymentConfig,
}) => {
  const { formatMessage } = useIntl();
  const content = useSelector(selectContent);
  const getCardIcons = () => {
    return paymentProvider === PaymentProvidersEnum.AMAZONPAY ? (
      <AmazonPaySVG
        className='amazon-pay-icon'
        sx={{ display: 'flex', alignItems: 'center' }}
      />
    ) : (
      <div
        className='payment payment-icons-container'
        sx={{ display: 'flex', alignItems: 'center', flexShrink: 1 }}
      >
        <PaymentIconsContainer
          paymentDesignName='split'
          creditCardType={creditCardType}
          paymentConfig={paymentConfig}
        />
      </div>
    );
  };

  const getPaymentNameText = () => {
    return paymentProvider === PaymentProvidersEnum.AMAZONPAY
      ? 'Amazon Pay'
      : resolveTicketingCMSStringOrDefault(
          formatMessage(messages.multiplePaymentProvidersCardLabel),
          content.payment.multiplePaymentProvidersCardLabel
        );
  };

  return (
    <>
      {!hasOnePaymentOption && (
        <Box
          sx={{
            fontWeight: 'bold',
            textAlign: 'left',
            textTransform: 'uppercase',
            flexGrow: 1,
            mr: 1,
            position: 'relative',
            top: 0,
          }}
        >
          <h3> {getPaymentNameText()}</h3>
        </Box>
      )}
      {getCardIcons()}
    </>
  );
};

export default CreditCardPaymentsHeadingContent;
