import { useEffect } from 'react';

import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import { LanguageCulture } from '../@types/configTypes';
import { actionCreators } from '../store/ActionCreators';
import {
  selectConfig,
  selectSelectedLanguageCulture,
} from '../store/Selectors';

export const useInitializeLanguageCulture = () => {
  const [cookies] = useCookies();
  const dispatch = useDispatch();

  const config = useSelector(selectConfig);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);

  const preferredLanguageCulture: LanguageCulture =
    cookies?.preferredLanguageCulture;

  const languageCulture: LanguageCulture = config?.languageCulture;

  useEffect(() => {
    if (!selectedLanguageCulture && preferredLanguageCulture) {
      dispatch(
        actionCreators.setSelectedLanguageCulture(preferredLanguageCulture)
      );
    } else if (!selectedLanguageCulture && languageCulture) {
      dispatch(actionCreators.setSelectedLanguageCulture(languageCulture));
    }

    if (selectedLanguageCulture) {
      document.documentElement.lang = selectedLanguageCulture;
    }
  }, [
    config,
    dispatch,
    languageCulture,
    preferredLanguageCulture,
    selectedLanguageCulture,
  ]);
};
