/** @jsxImportSource theme-ui */
import React from 'react';

import { Offcanvas, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { actionCreators } from '../../../store/ActionCreators';
import {
  selectGrandTotalAfterDiscountsInCents,
  selectIsCartSummeryOpen,
} from '../../../store/Selectors';
import { ReactComponent as CartEmpty } from '../../../svgs/cartEmpty.svg';
import { ReactComponent as CartFull } from '../../../svgs/cartFull.svg';
import { ReactComponent as Cross } from '../../../svgs/cross.svg';
import CartSummary from '../cartsummary/CartSummary';

interface Props {
  hideTotal: boolean;
  isRefund: boolean;
}

const CartSummaryOffcanvas: React.FC<Props> = ({ hideTotal, isRefund }) => {
  const dispatch = useDispatch();

  const grandTotalToUse = useSelector(selectGrandTotalAfterDiscountsInCents);
  const isCartSummeryOpen = useSelector(selectIsCartSummeryOpen);

  return (
    <Offcanvas
      show={isCartSummeryOpen}
      onHide={() => dispatch(actionCreators.setCartSummaryOpen(false))}
      sx={{
        backgroundColor: 'sidebarBackground',
        color: 'mostReadableOnSidebarBackground',
        zIndex: '1050',

        svg: {
          '.icon': {
            fill: 'mostReadableOnSidebarBackground',
          },
        },
      }}
    >
      <div
        id='offcanvas-cart-summary'
        sx={{
          overflow: 'scroll',
        }}
      >
        <div
          id='offcanvas-cart-summary-header'
          sx={{
            background: 'sidebarBackground',
            padding: 3,
            position: 'sticky',
            top: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            svg: {
              height: '20px',
              width: 'auto',
            },
          }}
        >
          {grandTotalToUse ? <CartFull /> : <CartEmpty />}
          <Button
            variant='link'
            onClick={() => dispatch(actionCreators.setCartSummaryOpen(false))}
            sx={{ display: 'flex' }}
          >
            <Cross />
          </Button>
        </div>

        <CartSummary hideTotal={hideTotal} isRefund={isRefund} />
      </div>
    </Offcanvas>
  );
};

export default CartSummaryOffcanvas;
