/** @jsxImportSource theme-ui */
import React, { useCallback, useEffect, useState } from 'react';

import classnames from 'classnames';
import { Button, Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from 'theme-ui';

import messages from './intl';

import { GlobalState } from '../../../@types/modelTypes';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectDazzlerLocale,
  selectTandCsAccepted,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import CheckBoxButton from '../../common/checkboxbutton/CheckBoxButton';
import RichText from '../../common/richtext/RichText';
import { resolveLocalisedStringOrDefault } from '../helpers';
import globalMessages from '../intl';
import { WidgetData } from '../types';

interface Props {
  widget: WidgetData<'TicketingCMSJourneyPurchaserDetailsWidget'>;
  isPageValidated: boolean;
  showTermsAndConditionsCheckbox: boolean;
  onEdit?: () => void;
}

const TermsAndConditions: React.FC<Props> = ({
  widget,
  isPageValidated,
  showTermsAndConditionsCheckbox,
  onEdit,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const isTandCsAccepted = useSelector(selectTandCsAccepted);
  const isTermsAndConditionsChecked = useSelector(
    (state: GlobalState) => state.termsAndConditionsChecked
  );
  const locale = useSelector(selectDazzlerLocale);

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleCheckboxClick = useCallback(() => {
    if (isPageValidated && !!onEdit) {
      onEdit();
    }
    dispatch(
      actionCreators.setTermsAndConditionsChecked(!isTermsAndConditionsChecked)
    );
  }, [dispatch, isPageValidated, isTermsAndConditionsChecked, onEdit]);

  const isFormValid = isPageValidated && !isTandCsAccepted;

  const termsAndConditionsButtonAriaLabelText = formatMessage(
    messages.termsAndConditionsButtonAriaLabelText
  );

  useEffect(() => {
    dispatch(
      actionCreators.setShowTermsAndConditionsCheckbox(
        showTermsAndConditionsCheckbox
      )
    );
  }, [dispatch, showTermsAndConditionsCheckbox]);

  return (
    <Flex
      className={classnames('terms-and-conditions', isFormValid && 'invalid')}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: 4,
        p: showTermsAndConditionsCheckbox ? '7px' : 0,
        svg: {
          '.a': {
            fill: 'websiteBackground',
          },

          '.b, .c': {
            fill: 'primary',
          },
        },

        '&.invalid': {
          borderColor: 'uiError',
          boxShadow: 'errorShadow',
        },
      }}
    >
      {showTermsAndConditionsCheckbox && (
        <Flex
          sx={{ alignItems: 'center', mr: 2 }}
          className='checkbox-with-text'
        >
          <CheckBoxButton
            checked={isTermsAndConditionsChecked}
            onClick={handleCheckboxClick}
          />
        </Flex>
      )}
      <Flex sx={{ alignItems: 'center', flexGrow: 1 }}>
        <p sx={{ m: 0 }} data-testid='terms-conditions'>
          {resolveLocalisedStringOrDefault(
            formatMessage(messages.termsAndConditionsText),
            locale,
            widget.shape?.termsAndConditionsText
          )}{' '}
          <Button
            variant='link'
            className='a'
            onClick={toggleModal}
            data-testid='terms-conditions-btn'
          >
            {resolveLocalisedStringOrDefault(
              formatMessage(messages.termsAndConditionsLinkText),
              locale,
              widget.shape?.termsAndConditionsLinkText
            )}
          </Button>
        </p>
        <Modal
          className='layout-modal'
          show={showModal}
          onHide={toggleModal}
          centered
          backdrop='static'
          keyboard={false}
          data-testid='terms-conditions-modal'
          aria-label={termsAndConditionsButtonAriaLabelText}
        >
          <Modal.Header>
            <Modal.Title>
              {formatMessage(messages.termsAndConditionsHeading)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RichText
              text={resolveLocalisedStringOrDefault(
                formatMessage(messages.termsAndConditionsRichText),
                locale,
                widget.shape?.termsAndConditionsRichText
              )}
            />
          </Modal.Body>
          <Modal.Footer>
            <ActionButton
              onClick={toggleModal}
              mb={0}
              mt={0}
              modal
              variant='primary'
            >
              {formatMessage(globalMessages.closeButtonText)}
            </ActionButton>
          </Modal.Footer>
        </Modal>
      </Flex>
    </Flex>
  );
};

export default TermsAndConditions;
