/** @jsxImportSource theme-ui */
import React, { forwardRef } from 'react';

import { Box } from 'theme-ui';

interface Props {
  warningTitle?: string;
  warningMessage?: string;
}

const WarningMessage = forwardRef<HTMLDivElement, Props>(
  ({ warningTitle, warningMessage }, ref: React.Ref<HTMLDivElement>) => {
    if (!warningMessage) {
      return null;
    }

    return (
      <Box
        className='warning-container'
        data-testid='warning-message'
        sx={{ my: 4, p: 4 }}
        role='alert'
        aria-live='assertive'
        ref={ref}
        aria-atomic='true'
      >
        <span className='visually-hidden'>{`${warningTitle} ${warningMessage}`}</span>
        <h3 sx={{ mt: 0, mb: 1, textAlign: 'center' }}>{warningTitle}</h3>
        <p sx={{ m: 0 }}>{warningMessage}</p>
      </Box>
    );
  }
);

WarningMessage.displayName = 'WarningMessage';

export default WarningMessage;
