/** @jsxImportSource theme-ui */
import React, { useState, useEffect, useCallback } from 'react';

import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { PEACH_CODES } from '../../../constants';
import { createRefund } from '../../../services/Helpers';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCustomer,
  selectLoginUrl,
  selectRefundData,
  selectToken,
} from '../../../store/Selectors';
import ContainedRow from '../../common/layout/ContainedRow';
import RefundConfirmed from '../../common/refund/RefundConfirmed';
import RefundRequest from '../../common/refund/RefundRequest';
import DazzlerWrapper from '../../dazzlercommon/DazzlerWrapper';
import LegacyWrapper from '../../dazzlercommon/LegacyWrapper';
import ShowtimeHeroLegacyWrapper from '../../widgets/showtimehero/ShowtimeHeroLegacyWrapper';

const Refund: React.FC = () => {
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { orderId } = useParams();

  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const refundData = useSelector(selectRefundData);
  const bookingData = useSelector(selectBookingData);
  const token = useSelector(selectToken);
  const loginUrl = useSelector(selectLoginUrl);

  const [refundConfirmed, setRefundConfirmed] = useState(false);

  const handleLogInClick = () => {
    const returnPath = `/api/refund/redirect/${orderId}`;
    const url = `${loginUrl}?return=${returnPath}`;
    window.location.assign(url);
  };

  const handleRedirectToError = useCallback(
    (code: number) => {
      navigate({
        pathname: '/error',
        search: `?code=${code}`,
      });
    },
    [navigate]
  );

  const handleSubmitRefund = async (
    isLoyaltyRefund: boolean,
    refundIncludesServiceCharge: boolean
  ) => {
    dispatch(actionCreators.setLoading(true));

    const data = {
      IsLoyaltyRefund: isLoyaltyRefund,
      RefundIncludesServiceCharge: refundIncludesServiceCharge,
      DataToken: token,
    };

    const response = await backend.post('api/Refund/Complete/', data);
    if (response.ok && response.content.peachCode === PEACH_CODES.refunded) {
      setRefundConfirmed(true);
    } else if (response.content.peachCode === PEACH_CODES.refundNotPermitted) {
      handleRedirectToError(response.content.peachCode);
    } else if (response.ok) {
      handleRedirectToError(response.content.peachCode);
    } else {
      handleRedirectToError(PEACH_CODES.unknownError);
    }

    dispatch(actionCreators.setLoading(false));
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(actionCreators.setLoading(true));
      const circuitId = searchParams.get('circuitId') ?? undefined;

      const { requestData } = cookies;
      const data = {
        externalOrderId: orderId,
        requestData,
      };

      let url = `api/Refund/StartSession/`;
      if (circuitId) {
        url += `?circuitId=${circuitId}`;
      }
      const response = await backend.post(url, data);
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        const startRefundModel = response.content;
        const { circuit } = startRefundModel;

        const refund = createRefund(startRefundModel, customer);
        dispatch(actionCreators.setRefund(refund));

        if (circuit) {
          dispatch(actionCreators.setDazzler(response.content.circuit.dazzler));
          dispatch(
            actionCreators.setDazzlerContent(
              response.content.circuit.dazzlerContent
            )
          );
          dispatch(
            actionCreators.setDazzlerSteps(
              response.content.circuit.dazzlerSteps?.refund
            )
          );
        }

        if (
          !refund.refundData.isRefundPermitted &&
          !refund.refundData.isRefundAllowedForMemberLevelId
        ) {
          handleRedirectToError(PEACH_CODES.tooLateForRefund);
        }
      } else if (response.ok) {
        dispatch(
          actionCreators.setCircuitConfig(response.content.circuit.config)
        );
        dispatch(
          actionCreators.setCircuitContent(response.content.circuit.content)
        );
        handleRedirectToError(response.content.peachCode);
      } else {
        handleRedirectToError(PEACH_CODES.unknownError);
      }
      dispatch(actionCreators.setLoading(false));
    };

    if (refundData?.externalOrderId !== orderId) {
      fetchData();
    }
  }, [
    refundData,
    orderId,
    dispatch,
    cookies,
    handleRedirectToError,
    customer,
    searchParams,
  ]);

  if (!config || !content || !refundData || !bookingData) return null;

  const { bookingReferenceText, ticketsEmailedToText } = content.refund;

  return (
    <>
      <DazzlerWrapper step='refund' />
      <LegacyWrapper step='refund'>
        <div
          className='refund'
          data-testid='refund'
          sx={{ textAlign: 'center' }}
        >
          <ContainedRow>
            <h1>{content.refund.title}</h1>
            <ShowtimeHeroLegacyWrapper />
            <h2 sx={{ mt: 5 }}>{bookingReferenceText}</h2>
            {refundData.codeUsedInBarcode && (
              <p>{refundData.codeUsedInBarcode}</p>
            )}

            <h2 sx={{ mt: 6 }}>{ticketsEmailedToText}</h2>
            {customer.email && <p>{customer.email}</p>}
          </ContainedRow>

          <ContainedRow styles={{ mt: 6 }}>
            {!refundConfirmed && (
              <RefundRequest
                handleSubmitRefund={handleSubmitRefund}
                handleLogInClick={handleLogInClick}
              />
            )}
            {refundConfirmed && <RefundConfirmed />}
          </ContainedRow>
        </div>
      </LegacyWrapper>
    </>
  );
};

export default Refund;
