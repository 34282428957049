/** @jsxImportSource theme-ui */
import React, { useEffect, useRef } from 'react';

import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import GiftCardBreakDown from './GiftCardBreakDown';
import GiftCardDonationNotIncludedMessage from './GiftCardDonationMessage';

import { CheckedGiftCard } from '../../../../@types/modelTypes';
import {
  calculateGiftCardRemainingBalance,
  displayGiftCardAmountChargedInCents,
} from '../../../../services/GiftCardHelpers';
import { displayPrice } from '../../../../services/Helpers';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectAppliedGiftCards,
  selectContent,
  selectCurrencyConfig,
  selectGrandTotalAfterDiscountsInCents,
  selectGrandTotalWithoutDonationAndRewards,
  selectTandCsAccepted,
} from '../../../../store/Selectors';
import ActionButton from '../../../common/actionbutton/ActionButton';
import { resolveTicketingCMSStringOrDefault } from '../../helpers';
import globalMessages from '../../intl';
import messages from '../intl';

interface Props {
  amountToPayByBankCard: number;
  checkedGiftCardState: CheckedGiftCard | null;
  isFormValid: boolean;
  giftCardCoversFullPayment: boolean;
  isMaxNumberOfGiftCardsUsed: boolean;
  setGiftCardNumberInputValue: (giftCardNumber: string) => void;
  setCheckedGiftCardState: (giftcard: CheckedGiftCard) => void;
  setIsCheckButtonDisabled: (isDisabled: boolean) => void;
  setPaymentFormVisibility: (isVisible: boolean) => void;
  isPageValidated: boolean;
  handleMakeGiftCardPaymentClick: () => void;
  handleValidatePage: () => void;
}

const GiftCardPaymentForm: React.FC<Props> = ({
  amountToPayByBankCard,
  checkedGiftCardState,
  isFormValid,
  giftCardCoversFullPayment,
  isMaxNumberOfGiftCardsUsed,
  setGiftCardNumberInputValue,
  setCheckedGiftCardState,
  setIsCheckButtonDisabled,
  setPaymentFormVisibility,
  isPageValidated,
  handleMakeGiftCardPaymentClick,
  handleValidatePage,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const appliedGiftCards = useSelector(selectAppliedGiftCards);
  const content = useSelector(selectContent);
  const isTandCsAccepted = useSelector(selectTandCsAccepted);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const grandTotalWithoutDonationAndRewards = useSelector(
    selectGrandTotalWithoutDonationAndRewards
  );
  const grandTotal = useSelector(selectGrandTotalAfterDiscountsInCents);

  const zeroBalanceDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      checkedGiftCardState?.isChecked &&
      checkedGiftCardState.giftCardBalanceInCents === 0
    ) {
      zeroBalanceDivRef.current?.focus();
    }
  }, [checkedGiftCardState]);

  const submitButtonText = `${content.payment.submitText} ${displayPrice(
    grandTotal,
    currencyConfig
  )}`;

  useEffect(() => {
    if (giftCardCoversFullPayment) {
      setIsCheckButtonDisabled(true);
      setPaymentFormVisibility(false);
    } else {
      setIsCheckButtonDisabled(isMaxNumberOfGiftCardsUsed);
      setPaymentFormVisibility(true);
    }
  }, [
    amountToPayByBankCard,
    giftCardCoversFullPayment,
    isMaxNumberOfGiftCardsUsed,
    setIsCheckButtonDisabled,
    setPaymentFormVisibility,
  ]);

  const handleApplyGiftCard = () => {
    if (
      !checkedGiftCardState ||
      appliedGiftCards.find(
        (giftCard) =>
          giftCard.giftCardNumber === checkedGiftCardState.giftCardNumber
      )
    )
      return;

    if (!isPageValidated) {
      handleValidatePage();
    }

    dispatch(actionCreators.addGiftCard(checkedGiftCardState));

    setGiftCardNumberInputValue('');

    setCheckedGiftCardState({
      giftCardNumber: '',
      giftCardPin: '',
      giftCardBalanceInCents: 0,
      isChecked: false,
      cardExpiry: '',
    });
  };

  const isPaymentValid = isPageValidated && isFormValid && isTandCsAccepted;

  if (!appliedGiftCards?.length && !checkedGiftCardState?.isChecked)
    return null;

  const giftCardRemainingBalanceDisplayPrice = displayPrice(
    calculateGiftCardRemainingBalance(
      appliedGiftCards,
      grandTotalWithoutDonationAndRewards
    ),
    currencyConfig
  );

  return (
    <>
      <Box data-testid='gift-card-split-payment' sx={{ mt: 5 }}>
        {checkedGiftCardState &&
          checkedGiftCardState.giftCardBalanceInCents > 0 && (
            <>
              {checkedGiftCardState.isExpired ? (
                <p sx={{ textTransform: 'uppercase' }}>
                  {resolveTicketingCMSStringOrDefault(
                    formatMessage(messages.giftCardExpiredText),
                    content.payment.giftCardExpiredText
                  )}{' '}
                  <strong>{checkedGiftCardState.cardExpiry}</strong>
                </p>
              ) : (
                <>
                  <p sx={{ textTransform: 'uppercase' }}>
                    {resolveTicketingCMSStringOrDefault(
                      formatMessage(messages.giftCardBalanceText),
                      content.payment.giftCardBalanceText
                    )}{' '}
                    <strong>
                      {displayPrice(
                        checkedGiftCardState.giftCardBalanceInCents,
                        currencyConfig
                      )}
                    </strong>
                  </p>
                  {checkedGiftCardState.cardExpiry && (
                    <p sx={{ textTransform: 'uppercase' }}>
                      {resolveTicketingCMSStringOrDefault(
                        formatMessage(messages.giftCardExpirationDateText),
                        content.payment.giftCardExpirationDateText
                      )}{' '}
                      <strong>{checkedGiftCardState.cardExpiry}</strong>
                    </p>
                  )}

                  <GiftCardDonationNotIncludedMessage />

                  <ActionButton
                    onClick={handleApplyGiftCard}
                    mt={3}
                    mb={0}
                    variant='primary'
                  >
                    {resolveTicketingCMSStringOrDefault(
                      formatMessage(messages.giftCardPayButtonText),
                      content.payment.giftCardPayButtonText
                    )}
                  </ActionButton>
                </>
              )}
            </>
          )}

        {checkedGiftCardState?.isChecked &&
          checkedGiftCardState.giftCardBalanceInCents === 0 && (
            <Box
              ref={zeroBalanceDivRef}
              className='info-container'
              sx={{ mt: 5, p: 4, textAlign: 'center' }}
              role='status'
              aria-live='assertive'
            >
              <p>
                {resolveTicketingCMSStringOrDefault(
                  formatMessage(messages.giftCardZeroBalanceText),
                  content.payment.giftCardZeroBalanceText
                )}
              </p>
            </Box>
          )}

        {appliedGiftCards?.length > 0 && (
          <Box
            className='info-container'
            sx={{ mt: 5, p: 4, textAlign: 'center' }}
          >
            {!checkedGiftCardState?.isChecked && (
              <>
                <p>
                  <b>
                    {displayGiftCardAmountChargedInCents(
                      appliedGiftCards,
                      0,
                      grandTotalWithoutDonationAndRewards,
                      currencyConfig
                    )}
                  </b>{' '}
                  {resolveTicketingCMSStringOrDefault(
                    formatMessage(messages.giftCardAppliedText),
                    content.payment.giftCardAppliedText
                  )}{' '}
                  <b>
                    {displayPrice(
                      appliedGiftCards[appliedGiftCards.length - 1]
                        .giftCardBalanceInCents,
                      currencyConfig
                    )}
                  </b>{' '}
                  {resolveTicketingCMSStringOrDefault(
                    formatMessage(messages.giftCardAppliedPartTwoText),
                    content.payment.giftCardAppliedPartTwoText
                  ).replace(
                    '##giftCardNumber##',
                    `${appliedGiftCards[
                      appliedGiftCards.length - 1
                    ].giftCardNumber.slice(-4)}`
                  )}
                </p>

                {giftCardCoversFullPayment && (
                  <p>
                    {resolveTicketingCMSStringOrDefault(
                      formatMessage(messages.giftCardRemainingBalanceText),
                      content.payment.giftCardRemainingBalanceText
                    ).includes('##RemainingBalance##')
                      ? resolveTicketingCMSStringOrDefault(
                          formatMessage(messages.giftCardRemainingBalanceText),
                          content.payment.giftCardRemainingBalanceText
                        ).replace(
                          '##RemainingBalance##',
                          giftCardRemainingBalanceDisplayPrice
                        )
                      : resolveTicketingCMSStringOrDefault(
                          formatMessage(messages.giftCardRemainingBalanceText),
                          content.payment.giftCardRemainingBalanceText
                        ) +
                        ' ' +
                        giftCardRemainingBalanceDisplayPrice}
                  </p>
                )}

                {!giftCardCoversFullPayment && !isMaxNumberOfGiftCardsUsed && (
                  <p sx={{ mt: 2 }}>
                    {resolveTicketingCMSStringOrDefault(
                      formatMessage(messages.giftCardPayRemainingBalanceText),
                      content.payment.giftCardPayRemainingBalanceText
                    )}
                  </p>
                )}

                {!giftCardCoversFullPayment && isMaxNumberOfGiftCardsUsed && (
                  <p sx={{ mt: 2 }}>
                    {resolveTicketingCMSStringOrDefault(
                      formatMessage(
                        messages.giftCardPayRemainingBalanceByCardText
                      ),
                      content.payment.giftCardPayRemainingBalanceByCardText
                    )}
                  </p>
                )}
              </>
            )}

            <Box
              className={classNames(
                checkedGiftCardState?.isChecked || 'box-separator'
              )}
              mt={checkedGiftCardState?.isChecked ? 0 : 4}
              pt={checkedGiftCardState?.isChecked ? 0 : 4}
            >
              <p>
                <b>{displayPrice(amountToPayByBankCard, currencyConfig)}</b>{' '}
                {resolveTicketingCMSStringOrDefault(
                  formatMessage(messages.giftCardStillToPayLabel),
                  content.payment.giftCardStillToPayLabel
                )}
              </p>
            </Box>
          </Box>
        )}
      </Box>

      <GiftCardBreakDown variant='paymentForm' pricingStrategy='journey' />

      {giftCardCoversFullPayment && (
        <ActionButton
          onClick={handleMakeGiftCardPaymentClick}
          showIcon
          mb={3}
          warningMessage={resolveTicketingCMSStringOrDefault(
            formatMessage(globalMessages.formErrorsMessage),
            content.payment.formErrorsMessage
          )}
          warningTitle={resolveTicketingCMSStringOrDefault(
            formatMessage(globalMessages.formErrorsSubTitle),
            content.payment.formErrorsSubTitle
          )}
          showWarningMessage={!isPaymentValid}
          variant='primary'
        >
          {submitButtonText}
        </ActionButton>
      )}
    </>
  );
};

export default GiftCardPaymentForm;
