import { defineMessages } from 'react-intl';

export default defineMessages({
  bookingReferenceText: {
    id: 'refund-details.booking-reference-text',
    defaultMessage: 'Your booking reference is:',
  },
  ticketsEmailedToText: {
    id: 'refund-details.tickets-emailed-to-text',
    defaultMessage: 'Your confirmation will be emailed to you at:',
  },
  loyaltyLoginRefundRequestMessage: {
    id: 'refund-details.loyalty-login-refund-request-message-richtext',
    defaultMessage:
      '<p>Members enjoy guaranteed refunds. Log in to have the transaction entirely refunded, including the booking fee.</p>',
  },
  continueAsGuestMessage: {
    id: 'refund-details.continue-as-guest-message-richtext',
    defaultMessage:
      '<p>If you are not a member, the refund will not include the booking fee.</p>',
  },
  genericRefundRequestMessage: {
    id: 'refund-details.generic-refund-request-message-richtext',
    defaultMessage:
      '<p>You are about to request a refund for your tickets.</p>',
  },
  loyaltyRefundRequestConfirmation: {
    id: 'refund-details.loyalty-refund-request-confirmation-message-richtext',
    defaultMessage:
      '<p>You are about to request a refund for your tickets.</p>',
  },
  nonLoyaltyRefundRequestConfirmation: {
    id: 'refund-details.non-loyalty-refund-request-confirmation-message-richtext',
    defaultMessage:
      '<p>You are about to request a refund for your tickets.</p>',
  },
  refundAgreeTermsText: {
    id: 'refund-details.refund-agree-terms-text-message-richtext',
    defaultMessage:
      '<p>Yes, I understand that my refund will only be for the tickets, and not include booking fee.</p>',
  },
  refundRequestedMessage: {
    id: 'refund-details.refund-requested-message-richtext',
    defaultMessage:
      '<p>Thank you! Your refund is now being processed, you will receive a confirmation email shortly.</p>',
  },
});
