/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';

import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import { getTotalPriceToDisplay } from '../../../services/Helpers';
import {
  selectContentCartSummary,
  selectCurrencyConfig,
  selectGrandTotalAfterDiscountsInCents,
  selectJourneyTypeConfig,
  selectRefundTotalInCents,
  selectSelectedSeasonPass,
} from '../../../store/Selectors';

interface Props {
  hideTotal: boolean;
  isRefund: boolean;
}

const CartSummaryTotal: React.FC<Props> = ({ hideTotal, isRefund }) => {
  const { isLargeScreenWidth } = useScreenWidth();

  const contentCartSummary = useSelector(selectContentCartSummary);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const grandTotalWithDiscount = useSelector(
    selectGrandTotalAfterDiscountsInCents
  );
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const refundTotalInCents = useSelector(selectRefundTotalInCents);
  const selectedSeasonPass = useSelector(selectSelectedSeasonPass);

  if (hideTotal) return null;

  const totalPriceToDisplay = getTotalPriceToDisplay(
    isRefund,
    refundTotalInCents,
    grandTotalWithDiscount,
    hideTotal,
    journeyTypeConfig,
    selectedSeasonPass,
    currencyConfig
  );

  return (
    <div
      className='cart-summary-total cart-summary-row cart-summary-row-flex bottom-border'
      data-testid='cs-total'
      sx={{
        background: 'sidebarBackground',
        bottom: 0,
        position: isLargeScreenWidth ? 'relative' : 'sticky',
      }}
    >
      <span className='h1' style={{ textTransform: 'uppercase' }}>
        {contentCartSummary.totalLabel}
      </span>
      <span className='h1'>{totalPriceToDisplay}</span>
    </div>
  );
};

export default CartSummaryTotal;
