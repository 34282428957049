/** @jsxImportSource theme-ui */
import React, { useEffect, useState, useRef } from 'react';

import classnames from 'classnames';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Flex } from 'theme-ui';

import messages from './intl';

import {
  AttributeWarning,
  MovieRatingWarning,
  ScreenWarning,
} from '../../../@types/contentTypes';
import { ShowtimeHeroConfig } from '../../../@types/modelTypes';
import { CommonTrackingEvent } from '../../../@types/trackingTypes';
import { useAnalytics } from '../../../analytics/analyticsContext';
import { JOURNEY_TYPES, SEASON_PASS_STEPS } from '../../../constants';
import * as StepPaths from '../../../constants/stepPaths';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import {
  getDisplayDateAndTime,
  renderShowtimeAttributeList,
  getScreenWarning,
  getAttributeWarnings,
  getMovieRatingWarning,
} from '../../../services/Helpers';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectCartSummary,
  selectConfig,
  selectContent,
  selectCustomer,
  selectJourneyTypeConfig,
  selectSelectedLanguageCulture,
  selectShowtimes,
  selectStep,
} from '../../../store/Selectors';
import { ReactComponent as SummaryCloseSvg } from '../../../svgs/summaryClose.svg';
import { ReactComponent as SummaryEditSvg } from '../../../svgs/summaryEdit.svg';
import { trapFocus } from '../../../utilities/trapFocus';
import AddToCalendar from '../../common/addtocalendar/AddToCalendar';
import CartSummaryDiscount from '../../common/cartsummary/CartSummaryDiscount';
import CartSummaryItems from '../../common/cartsummary/CartSummaryItems';
import CartSummaryTotal from '../../common/cartsummary/CartSummaryTotal';
import ContainedRow from '../../common/layout/ContainedRow';
import MobileWallets from '../../common/mobilewallets/MobileWallets';
import SelectedSeasonPassImage from '../../common/seasonpass/SelectedSeasonPassImage';
import SelectedSeasonPassTitleAndLocation from '../../common/seasonpass/SelectedSeasonPassTitleAndLocation';
import ShowtimePicker from '../../common/showtimepicker/ShowtimePicker';
import ShowtimeWarning from '../../common/showtimewarning/ShowtimeWarning';
import { resolveTicketingCMSStringOrDefault } from '../helpers';
import globalMessages from '../intl';

interface Props {
  barcodeUrl?: string;
  displayAddToCalendar?: boolean;
  displayBarcode?: boolean;
  displayConfirmationNumber?: boolean;
  displayMobileWallets?: boolean;
  orderId?: string;
  showtimeHeroConfig: ShowtimeHeroConfig;
}

const ShowtimeHero: React.FC<Props> = ({
  barcodeUrl,
  displayAddToCalendar,
  displayBarcode,
  displayConfirmationNumber,
  displayMobileWallets,
  orderId,
  showtimeHeroConfig,
}) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { formatMessage } = useIntl();

  const { pathname } = useLocation();
  const { isLargeScreenWidth } = useScreenWidth();

  const bookingData = useSelector(selectBookingData);
  const cartSummary = useSelector(selectCartSummary);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const showtimes = useSelector(selectShowtimes);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const step = useSelector(selectStep);

  const [showtimePickerOpen, setShowtimePickerOpen] = useState(false);

  const modalRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLButtonElement | null>(null);

  const displaySummary =
    (pathname.startsWith('/payment') && !isLargeScreenWidth) ||
    (pathname.startsWith('/confirmation') && !isLargeScreenWidth);

  const allowShowtimeChange = !!showtimeHeroConfig.allowShowtimeChange;

  const displayShowtimePicker =
    allowShowtimeChange &&
    (pathname.startsWith('/seats/') || pathname.startsWith('/tickets/'));

  const attributeWarnings: AttributeWarning[] = getAttributeWarnings(
    cartSummary.sessionAttributes,
    content.attributeWarnings
  );

  const screenWarning: ScreenWarning | undefined = getScreenWarning(
    cartSummary.screen,
    content.screenWarnings,
    config.currentCinema.cinemaId
  );

  const movieRatingWarnings: MovieRatingWarning[] = getMovieRatingWarning(
    cartSummary.rated,
    content.movieRatingWarnings
  );

  const displayWarningMessage =
    pathname.startsWith(`/${StepPaths.WARNING}`) &&
    (!!attributeWarnings.length ||
      screenWarning ||
      !!movieRatingWarnings.length);

  const showShowtimeHeroExtendedArea =
    displayShowtimePicker || displayWarningMessage;

  const dot = <span className='dot-separator'>·</span>;

  useEffect(() => {
    const fetchSessions = async () => {
      const url = `api/ShowtimePicker/Showtimes/
      ${bookingData.circuitId}/
      ${bookingData.cinemaId}/
      ${bookingData.filmId}`;

      const response = await backend.get(url);

      if (response.ok) {
        response.content &&
          dispatch(actionCreators.setShowtimes(response.content));
      }
    };
    if (
      showShowtimeHeroExtendedArea &&
      bookingData &&
      !showtimes &&
      !pathname.startsWith('/confirmation') &&
      !pathname.startsWith('/refund')
    ) {
      fetchSessions();
    }
  }, [
    bookingData,
    dispatch,
    showShowtimeHeroExtendedArea,
    pathname,
    showtimes,
  ]);

  const handleShowSessions = () => {
    setShowShowtimes(true);
    analytics?.track(CommonTrackingEvent.CHANGE_SHOWTIME_CLICK);
  };

  const handleHideSessions = () => {
    document.documentElement.classList.remove('no-scroll');
    document.body.classList.remove('no-scroll');
    setShowtimePickerOpen(false);
  };

  const setShowShowtimes = (show: boolean) => {
    if (show) {
      document.documentElement.classList.add('no-scroll');
      document.body.classList.add('no-scroll');
      setShowtimePickerOpen(show);
    } else {
      handleHideSessions();
    }
  };

  useEffect(() => {
    if (showtimePickerOpen) {
      const modal = modalRef.current;
      if (modal) {
        modal.focus();
        const cleanup = trapFocus(modal);
        return cleanup;
      }
    } else {
      triggerRef.current?.focus();
    }
  }, [showtimePickerOpen]);

  useEffect(() => {
    const modal = modalRef.current;
    const closeModalHandler = () => handleHideSessions();

    if (modal) {
      modal.addEventListener('closeModal', closeModalHandler);
    }

    return () => {
      if (modal) {
        modal.removeEventListener('closeModal', closeModalHandler);
      }
    };
  }, []);

  const displaySeasonPassSummary =
    journeyTypeConfig.type === JOURNEY_TYPES.SEASON_PASS &&
    step === SEASON_PASS_STEPS.PAYMENT;

  if (displaySeasonPassSummary) {
    return (
      <Box
        className='showtime-hero'
        data-testid='seasonpass-showtime-hero'
        sx={{
          boxShadow: 'mostReadableOnWebsiteBackgroundShadow',
          mt: 4,
          textAlign: 'center',
        }}
      >
        <ContainedRow>
          <SelectedSeasonPassImage />
          <SelectedSeasonPassTitleAndLocation />
          {!isLargeScreenWidth && (
            <Box
              className='cart-summary'
              sx={{
                backgroundColor: 'uiContainerBackground',
                borderTop: 'bodySeparator',
                '.cart-items': {
                  borderTop: 'none',
                },
                '.cart-summary-row': {
                  borderTop: 'bodySeparator',
                },
                mx: 4,
                mt: 1,
              }}
            >
              <CartSummaryItems />
              <CartSummaryDiscount />
              <CartSummaryTotal />
            </Box>
          )}
        </ContainedRow>
      </Box>
    );
  }

  if (
    !content ||
    !content.cartSummary ||
    !customer ||
    !cartSummary ||
    !cartSummary.sessionDate
  )
    return null;

  const backgroundImage = cartSummary.stillUrl
    ? cartSummary.stillUrl
    : cartSummary.posterUrl;

  const dateAndTime =
    cartSummary.sessionDate &&
    getDisplayDateAndTime(cartSummary.sessionDate, selectedLanguageCulture);

  const showtimeChangeButtonAriaDescriptionText = formatMessage(
    messages.showtimeChangeButtonAriaDescriptionText
  );

  return (
    <>
      {showShowtimeHeroExtendedArea && (
        <div
          className={classnames(showtimePickerOpen && 'showtime-picker-mask')}
          sx={{
            backgroundColor: 'bodyMask',
          }}
        />
      )}

      <div
        className={classnames(
          'showtime-hero',
          showShowtimeHeroExtendedArea &&
            showtimePickerOpen &&
            'hide-opacity showtime-picker-view'
        )}
        data-testid='showtime-hero'
        sx={{
          textAlign: 'center',
          boxShadow: 'mostReadableOnWebsiteBackgroundShadow',
          mt: 4,
          mb: showtimePickerOpen ? 4 : 0,
        }}
        ref={modalRef}
        tabIndex={-1}
        aria-label={
          showtimePickerOpen
            ? formatMessage(messages.showtimeChangeDialogAriaLabelText)
            : undefined
        }
        aria-modal={showtimePickerOpen ? 'true' : 'false'}
      >
        <div className='showtime-hero-top'>
          <div
            className='backdrop blur'
            style={{ backgroundImage: `url(${backgroundImage}?width=770)` }}
          />
          <div className='caption' role='none'>
            {showShowtimeHeroExtendedArea && showtimePickerOpen && (
              <Button
                className='close-showtime-picker-button'
                onClick={() => handleHideSessions()}
                variant='link'
                aria-label={formatMessage(
                  messages.showtimeChangeDialogCloseButtonAriaLabelText
                )}
              >
                <SummaryCloseSvg className='icon' />
              </Button>
            )}

            <h2>{cartSummary.title}</h2>

            {!(showShowtimeHeroExtendedArea && showtimePickerOpen) && (
              <>
                <p className='tiny'>
                  {cartSummary.rated && <span>{cartSummary.rated}</span>}
                  {cartSummary.formattedDuration && cartSummary.rated && (
                    <span>{dot}</span>
                  )}
                  {cartSummary.formattedDuration && (
                    <span>{cartSummary.formattedDuration}</span>
                  )}
                </p>
                <p className='small extra-margin'>
                  {renderShowtimeAttributeList(
                    cartSummary.sessionAttributes,
                    config.showtimeAttributeIconsInShowtimeHeroFormat,
                    true
                  )}
                </p>
              </>
            )}

            <p className='extra-margin'>
              <b>
                {resolveTicketingCMSStringOrDefault(
                  formatMessage(globalMessages.atLabel),
                  content.cartSummary.atLabel
                )}{' '}
              </b>
              {cartSummary.theaterLocationName}
              {dot}
              {cartSummary.screen}
            </p>
            {(!displayShowtimePicker ||
              (displayShowtimePicker && showtimePickerOpen)) && (
              <p>
                <b>
                  {resolveTicketingCMSStringOrDefault(
                    formatMessage(globalMessages.onLabel),
                    content.cartSummary.onLabel
                  )}
                </b>
                {dateAndTime?.displayDate} - {dateAndTime?.displayTime}
              </p>
            )}
          </div>
        </div>

        {displayBarcode && (
          <div sx={{ mt: 5, px: 4 }}>
            <img src={barcodeUrl} alt='ticket barcode' />
          </div>
        )}

        {displayMobileWallets && orderId && <MobileWallets orderId={orderId} />}

        {displayAddToCalendar && displayConfirmationNumber !== undefined && (
          <AddToCalendar
            displayConfirmationNumber={displayConfirmationNumber}
            locationCinemaName={config.currentCinema.title}
          />
        )}

        {displaySummary && (
          <div
            className='cart-summary'
            sx={{
              px: 4,
              backgroundColor: 'uiContainerBackground',
              '.cart-items:first-child': {
                borderTop: 'none',
              },
              '.cart-summary-row': {
                borderTop: 'bodySeparator',
              },
              '.cart-summary-total': {
                backgroundColor: 'uiContainerBackground',
              },
            }}
          >
            <CartSummaryItems />
            <CartSummaryDiscount />
            <CartSummaryTotal />
          </div>
        )}

        {(displayShowtimePicker || displayWarningMessage) && (
          <div
            className='showtime-hero-session-picker'
            sx={{
              backgroundColor: 'uiContainerBackground',
            }}
          >
            {showtimePickerOpen ? (
              <h2 sx={{ py: 2 }}>
                {resolveTicketingCMSStringOrDefault(
                  formatMessage(messages.selectShowtimeText),
                  content.selectShowtimeText
                )}
              </h2>
            ) : (
              displayShowtimePicker && (
                <Box sx={{ px: 4 }}>
                  <div sx={{ borderBottom: 'bodySeparator', py: 2 }}>
                    <p sx={{ m: 0 }}>
                      <b>
                        {resolveTicketingCMSStringOrDefault(
                          formatMessage(globalMessages.onLabel),
                          content.cartSummary.onLabel
                        )}{' '}
                      </b>
                      {dateAndTime?.displayDate} - {dateAndTime?.displayTime}
                    </p>
                  </div>
                </Box>
              )
            )}

            <Box
              className='showtime-picker'
              sx={{
                '.update-showtime-btn': {
                  color: 'mostReadableOnWebsiteBackground',

                  '.icon': {
                    '.a': {
                      fill: 'primary',
                    },
                  },

                  '&:hover': {
                    color: 'primary',
                  },
                },
              }}
            >
              {displayShowtimePicker && !showtimePickerOpen && (
                <Flex sx={{ justifyContent: 'center', py: 2 }}>
                  <Button
                    className='update-showtime-btn tiny'
                    onClick={() => handleShowSessions()}
                    variant='link'
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    ref={triggerRef}
                  >
                    {content.updateShowtimeText}
                    <SummaryEditSvg className='icon' />
                  </Button>
                </Flex>
              )}

              {showtimePickerOpen && (
                <ShowtimePicker
                  showtimeAttributeIconsInShowtimePickerFormat={
                    showtimeHeroConfig.attributeVariantShowtimePicker
                  }
                  showtimeChangeButtonAriaDescriptionText={
                    showtimeChangeButtonAriaDescriptionText
                  }
                />
              )}
            </Box>
          </div>
        )}

        {displayWarningMessage && (
          <ShowtimeWarning
            setShowShowtimes={setShowShowtimes}
            attributeWarnings={attributeWarnings}
            screenWarning={screenWarning}
            movieRatingWarnings={movieRatingWarnings}
          />
        )}
      </div>
    </>
  );
};

export default ShowtimeHero;
