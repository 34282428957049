/** @jsxImportSource theme-ui */
import React, { PropsWithChildren, useEffect, useRef } from 'react';

import classnames from 'classnames';
import { Collapse, Button } from 'react-bootstrap';
import { Box } from 'theme-ui';

interface BorderedCollapseProps {
  className?: string;
  closeButtonText: string;
  heading: string;
  setShow: (show: boolean) => void;
  show: boolean;
  headingClassName?: string;
}

const BorderedCollapse: React.FC<PropsWithChildren<BorderedCollapseProps>> = ({
  children,
  className,
  closeButtonText,
  heading,
  setShow,
  show,
  headingClassName,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (show && contentRef.current) {
      setTimeout(() => {
        contentRef.current?.focus();
      }, 0);
    }
  }, [show]);

  return (
    <div
      className={classnames('bordered-collapse', className)}
      sx={{
        backgroundColor: 'boxBackground',
        borderColor: 'boxBorderColor',
        color: 'mostReadableOnWebsiteBackground',
      }}
    >
      <Button
        className='bordered-collapse-btn'
        onClick={() => {
          setShow(!show);
        }}
        sx={{ textAlign: 'center' }}
        variant='link'
        aria-expanded={show}
      >
        <h3
          className={classnames('h3', headingClassName)}
          sx={{ textWrap: 'balance' }}
        >
          {heading}
        </h3>
        <span
          data-testid='bordered-collapse-close-button'
          className={classnames('float-end close-button', show && 'show')}
        >
          {closeButtonText}
        </span>
      </Button>
      <Collapse in={show} data-testid='bordered-collapse-content'>
        <div>
          <Box
            ref={contentRef}
            className='bordered-collapse-open'
            sx={{
              pt: 0,
              pb: 4,
              px: 4,
              ':focus': {
                outline: 'none',
              },
            }}
          >
            <div className='bordered-collapse-border-top'>{children}</div>
          </Box>
        </div>
      </Collapse>
    </div>
  );
};

export default BorderedCollapse;
