/** @jsxImportSource theme-ui */
import React, { useEffect, useCallback } from 'react';

import { createBrowserHistory } from 'history';
import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Box } from 'theme-ui';

import {
  ConfirmationModel,
  ConfirmationRequestModel,
} from '../../../@types/modelTypes';
import { TrackingEvent } from '../../../@types/trackingTypes';
import { BARCODE_FORMATS, forceNewJourneyKey } from '../../../constants';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import useAnalyticsTrackOnce from '../../../hooks/useAnalyticsTrackOnce';
import { useCinemaHomeUrlUpdater } from '../../../hooks/useCinemaHomeUrlUpdater';
import { useConfirmationTracking } from '../../../hooks/useConfirmationTracking';
import { createConfirmation } from '../../../services/Helpers';
import { getStepNumberForDisplay } from '../../../services/JourneyService';
import { buildQueryString } from '../../../services/QueryStringHelper';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectAppliedGiftCards,
  selectBookingData,
  selectConfig,
  selectConfirmationData,
  selectContent,
  selectCurrentCinemaHomeUrl,
  selectCustomer,
  selectGuestSessionToken,
  selectJourneyType,
  selectSource,
  selectStep,
  selectToken,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import Address from '../../common/address/Address';
import AddToCalendar from '../../common/addtocalendar/AddToCalendar';
import RegisterForCarParkingSection from '../../common/confirmation/RegisterForCarParkingSection';
import GoogleMap from '../../common/googlemap/GoogleMap';
import ContainedRow from '../../common/layout/ContainedRow';
import MobileWallets from '../../common/mobilewallets/MobileWallets';
import RichText from '../../common/richtext/RichText';
import SignUpSection from '../../common/signup/SignUpSection';
import DazzlerWrapper from '../../dazzlercommon/DazzlerWrapper';
import LegacyWrapper from '../../dazzlercommon/LegacyWrapper';
import GiftCardBreakDown from '../../widgets/giftcardpayment/giftcardcomponents/GiftCardBreakDown';
import ShowtimeHeroLegacyWrapper from '../../widgets/showtimehero/ShowtimeHeroLegacyWrapper';

const dazzlerJourneyTitle = 'Ticketing'; // could be seasonpases!! need a function to fix this

const Confirmation: React.FC = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { orderId } = useParams();
  const [cookies] = useCookies();
  const { isLargeScreenWidth } = useScreenWidth();

  const appliedGiftCards = useSelector(selectAppliedGiftCards);
  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const confirmationData = useSelector(selectConfirmationData);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const source = useSelector(selectSource);
  const step = useSelector(selectStep);
  const token = useSelector(selectToken);
  const guestSessionToken = useSelector(selectGuestSessionToken);
  const journeyType = useSelector(selectJourneyType);
  const currentCinemaHomeUrl = useSelector(selectCurrentCinemaHomeUrl);
  useConfirmationTracking('Ticket');

  useAnalyticsTrackOnce(TrackingEvent.TICKET_CHECKOUT);

  useCinemaHomeUrlUpdater();

  const fetchData = useCallback(async () => {
    dispatch(actionCreators.setLoading(true));
    const qs = buildQueryString({
      circuitId: searchParams.get('circuitId') ?? undefined,
      languageCulture: cookies.preferredLanguageCulture,
    });
    const url = `api/Confirmation/${orderId}${qs}`;

    let body: ConfirmationRequestModel | undefined;

    if (guestSessionToken) {
      body = {
        DataToken: token,
        GuestSessionToken: guestSessionToken,
      };
    }

    const response = await backend.post(url, body);
    if (response.ok) {
      const data = response.content as ConfirmationModel;
      const confirmation = createConfirmation(
        data,
        customer,
        journeyType,
        dazzlerJourneyTitle
      );
      dispatch(actionCreators.setConfirmation(confirmation));
    }

    dispatch(actionCreators.setGuestSessionToken(undefined));

    dispatch(actionCreators.setLoading(false));
  }, [
    dispatch,
    searchParams,
    cookies.preferredLanguageCulture,
    orderId,
    guestSessionToken,
    token,
    customer,
    journeyType,
  ]);

  // fetch data
  useEffect(() => {
    if (!confirmationData || confirmationData.externalOrderId !== orderId) {
      fetchData();
    }

    window.sessionStorage.setItem(forceNewJourneyKey, '1');
  }, [confirmationData, orderId, fetchData]);

  const history = createBrowserHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  history.listen(({ location, action }) => {
    if (action === 'POP') {
      window.location.replace(currentCinemaHomeUrl);
    }
  });

  if (!config || !content || !confirmationData) return null;

  const knownBarcodeFormat =
    BARCODE_FORMATS[config.currentCinema.barcodeFormat];
  const barcodeHeight = knownBarcodeFormat === 'QR' ? 150 : 60;
  const barcodeWidth = knownBarcodeFormat === 'QR' ? 150 : 264;
  const barcodeUrl = `/Image/GetBarcode/${confirmationData.externalOrderId}/${barcodeHeight}/${barcodeWidth}?circuitId=${config.circuitId}`;
  const shouldShowBarcode =
    knownBarcodeFormat !== 'None' && knownBarcodeFormat !== undefined;
  const showSummaryInShowtimeHero = !isLargeScreenWidth;

  const shouldShowConfirmationNumber =
    config.currentCinema.showConfirmationId &&
    confirmationData.bookingConfirmationId !==
      confirmationData.codeUsedInBarcode &&
    !!confirmationData.bookingConfirmationId;

  const shouldShowSignUpSection =
    config.signIn.isSignUpWithinBoostEnabled &&
    !bookingData?.isUserValidated &&
    token;

  return (
    <>
      <DazzlerWrapper step='confirmation' />
      <LegacyWrapper step='confirmation'>
        <div
          className='confirmation'
          data-testid='confirmation'
          sx={{ textAlign: 'center' }}
        >
          <ContainedRow>
            <h1>
              {getStepNumberForDisplay(step)}
              {content.confirmation.title}
            </h1>

            <ShowtimeHeroLegacyWrapper
              barcodeUrl={barcodeUrl}
              displayAddToCalendar={showSummaryInShowtimeHero}
              displayConfirmationNumber={shouldShowConfirmationNumber}
              displayBarcode={shouldShowBarcode && showSummaryInShowtimeHero}
              displayMobileWallets={showSummaryInShowtimeHero}
              orderId={orderId}
            />
          </ContainedRow>

          {shouldShowBarcode && !showSummaryInShowtimeHero && (
            <ContainedRow styles={{ mt: 6 }}>
              <img src={barcodeUrl} alt='ticket barcode' />
            </ContainedRow>
          )}

          {!showSummaryInShowtimeHero && (
            <>
              <MobileWallets orderId={orderId} />
              <AddToCalendar
                displayConfirmationNumber={shouldShowConfirmationNumber}
                locationCinemaName={config.currentCinema.title}
              />
            </>
          )}
          <ContainedRow>
            {shouldShowSignUpSection && <SignUpSection />}

            <h2 sx={{ mt: 7 }}>
              {content.confirmation.bookingReferenceSubTitle}
            </h2>
            {confirmationData.codeUsedInBarcode && (
              <p>{confirmationData.codeUsedInBarcode}</p>
            )}
            {config.currentCinema.showConfirmationId &&
              confirmationData.bookingConfirmationId !==
                confirmationData.codeUsedInBarcode && (
                <>
                  <h2 sx={{ mt: 7 }}>
                    {content.confirmation.confirmationIdSubTitle}
                  </h2>
                  {shouldShowConfirmationNumber && (
                    <p>{confirmationData.bookingConfirmationId}</p>
                  )}
                </>
              )}
            <h2 sx={{ mt: 7 }}>{content.confirmation.yourTicketsSubTitle}</h2>
            {customer.email && <p>{customer.email}</p>}

            <h2 sx={{ mt: 7 }}>
              {content.confirmation.collectTicketsSubTitle}
            </h2>
            <RichText
              text={content.confirmation.collectTicketsRichText}
              styles={{ textAlign: 'left' }}
            />
          </ContainedRow>

          <Address />

          <GoogleMap title={content.confirmation.googleMapSubTitle} />

          <RegisterForCarParkingSection />

          <ContainedRow>
            {appliedGiftCards && appliedGiftCards.length > 0 && (
              <>
                <h2 sx={{ mt: 7 }}>{content.confirmation.giftCardsSubTitle}</h2>
                <RichText
                  text={content.confirmation.giftCardsRichText}
                  styles={{ textAlign: 'left' }}
                />
                <Box className='giftcard-payment ' px={5}>
                  <GiftCardBreakDown
                    pricingStrategy='confirmation'
                    variant='confirmation'
                  />
                </Box>
              </>
            )}

            {(content.confirmation.aboutYourBookingTitle ||
              content.confirmation.aboutYourBookingRichText) && (
              <>
                <h2 sx={{ mt: 7 }}>
                  {content.confirmation.aboutYourBookingTitle}
                </h2>
                <RichText
                  text={content.confirmation.aboutYourBookingRichText}
                  styles={{ textAlign: 'left' }}
                />
              </>
            )}

            {content.confirmation.promoSpaceRichText && (
              <RichText
                text={content.confirmation.promoSpaceRichText}
                styles={{ mt: 7, textAlign: 'left' }}
              />
            )}
          </ContainedRow>
          {source !== 'app' && (
            <ActionButton
              onClick={() => (window.location.href = currentCinemaHomeUrl)}
              sticky={
                config.seats.useStickyButton && config.tickets.useStickyButton
              }
              showIcon
              contained
              hasMarginX
              variant='primary'
            >
              {content.continueButtonText}
            </ActionButton>
          )}

          {config.confirmation.trackingCodes && (
            <RichText
              text={config.confirmation.trackingCodes}
              className='d-none'
            />
          )}
        </div>
      </LegacyWrapper>
    </>
  );
};

export default Confirmation;
