import React from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import messages from './intl';

import {
  selectCurrentCinemaHomeUrl,
  selectDazzlerLocale,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';
import { resolveLocalisedStringOrDefault } from '../helpers';
import globalMessages from '../intl';
import { WidgetData } from '../types';

type Props = {
  widget: WidgetData<'TicketingCMSJourneyRefundDetailsWidget'>;
};

const RefundConfirmed: React.FC<Props> = ({ widget }) => {
  const { formatMessage } = useIntl();

  const currentCinemaHomeUrl = useSelector(selectCurrentCinemaHomeUrl);
  const locale = useSelector(selectDazzlerLocale);

  return (
    <ContainedRow dataTestId='refund-confirmed'>
      <RichText
        text={resolveLocalisedStringOrDefault(
          formatMessage(messages.refundRequestedMessage),
          locale,
          widget.shape?.refundRequestedMessage
        )}
      />
      <ActionButton
        onClick={() => (window.location.href = currentCinemaHomeUrl)}
        showIcon
        contained
        variant='primary'
      >
        {formatMessage(globalMessages.goBackToWebsiteButtonText)}
      </ActionButton>
    </ContainedRow>
  );
};

export default RefundConfirmed;
